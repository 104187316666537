<template>
  <div class="block">
    <button
      class="block__header"
      @click="
        () => {
          isBlockFullyDisplayed = !isBlockFullyDisplayed
        }
      "
    >
      <div class="header__title">
        <ReflectTag
          :text="getDataTypeTranslation(dataType)"
          :theme="THEME_BY_DATA_TYPE[dataType]"
        />
        <i class="title__arrow icon-tiny-arrow" />
        <div class="title__category">
          {{
            getMemberCategoryTranslation(
              dimension.meta.category!,
              MemberType.DIMENSION
            )
          }}
        </div>
        <i class="title__arrow icon-tiny-arrow" />
        <div class="title__dimension">
          <i
            :class="`dimension__icon icon-${getMemberFormatIcon(dimension)}`"
          />
          <label class="dimension__label">
            {{ getDimensionTranslation(dimension) }}
          </label>
        </div>
      </div>
      <i
        class="header__toggle icon-toggle"
        :class="{ 'header__toggle--on': isBlockFullyDisplayed }"
      />
    </button>
    <div
      v-if="isBlockFullyDisplayed"
      class="block__body"
    >
      <div class="body__first-col">
        <div
          class="first-col__total"
          :class="{ 'first-col__total--complete': isComplete }"
        >
          <i class="total__icon icon-data-health" />
          <p class="total__text">
            {{
              isComplete
                ? $t(
                    'settings.integrations.dataHealth.checkCompletenessDimensionBlock.completeTotal',
                    {
                      workforceCount,
                      dimension: getDimensionTranslation(dimension)
                    }
                  )
                : $t(
                    'settings.integrations.dataHealth.checkCompletenessDimensionBlock.incompleteTotal',
                    {
                      incompleteCount,
                      workforceCount,
                      dimension: getDimensionTranslation(dimension)
                    }
                  )
            }}
          </p>
        </div>
        <div
          v-if="impactedMeasures && impactedMeasures.length > 0"
          class="first-col__metrics"
        >
          <p class="metrics__title">
            {{
              $t(
                'settings.integrations.dataHealth.checkCompletenessDimensionBlock.impactedMetrics'
              )
            }}
          </p>
          <div
            v-for="measure in impactedMeasures"
            :key="measure.shortTitle"
            class="metrics__sequence"
          >
            <i :class="`sequence__icon icon-${getMemberFormatIcon(measure)}`" />
            <label class="sequence__label">
              {{ getDefaultMeasureTranslation(measure) }}
            </label>
          </div>
        </div>
        <div
          v-if="!isComplete"
          class="first-col__improve"
        >
          <p class="improve__title">
            {{
              $t(
                'settings.integrations.dataHealth.checkCompletenessDimensionBlock.howToImprove'
              )
            }}
          </p>
          <p class="improve__text">
            {{
              $t(
                'settings.integrations.dataHealth.checkCompletenessDimensionBlock.statement1'
              )
            }}
          </p>
          <p class="improve__text">
            {{
              $t(
                'settings.integrations.dataHealth.checkCompletenessDimensionBlock.statement2'
              )
            }}
          </p>
        </div>
      </div>
      <div class="body__second-col">
        <div class="second-col__list">
          <template v-if="!isComplete">
            <p class="list__header">
              {{
                $t(
                  'settings.integrations.dataHealth.checkCompletenessDimensionBlock.employeeToUpdate'
                )
              }}
            </p>
            <div
              v-for="employee in incompleteDataDisplayed"
              :key="employee.employeeDisplayNameWithId"
              class="list__item"
              :title="formatDate2(employee.lastIncompleteDate)"
            >
              <SettingsUserWithPicture
                class="item__name"
                :with-small-avatar="true"
                :username="
                  formatMemberValue(
                    employee.employeeDisplayNameWithId,
                    MemberFormat.NAME,
                    FormatContext.DEFAULT
                  )
                "
              />
              <p class="item__empty">
                {{
                  $t(
                    'settings.integrations.dataHealth.checkCompletenessDimensionBlock.empty'
                  )
                }}
              </p>
            </div>
            <div
              v-if="!isAllIncompleteDataDisplayed"
              class="list__tag"
              @click="() => (displayAllIncompleteData = true)"
            >
              <p class="tag">
                {{
                  $t(
                    'settings.integrations.dataHealth.checkCompletenessDimensionBlock.more',
                    {
                      count: incompleteCount - incompleteDataDisplayed.length
                    }
                  )
                }}
              </p>
            </div>
          </template>
          <div
            v-else
            class="list__header"
          >
            {{
              $t(
                'settings.integrations.dataHealth.checkCompletenessDimensionBlock.nothingToUpdate'
              )
            }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import type { DimensionHealthCheck } from '~/types/dataHealth'
import { THEME_BY_DATA_TYPE } from '~/constants/cube'
import {
  getDataTypeFromMemberTitle,
  getDataTypeTranslation,
  getDimensionFromTitle,
  getMemberCategoryTranslation,
  getDimensionTranslation,
  getMemberFormatIcon,
  getMeasureFromTitle,
  getDefaultMeasureTranslation
} from '~/services/explore'
import { MemberType } from '~/types/analytics'
import { formatMemberValue } from '~/services/analyticsFormat'
import { formatDate2 } from '~/services/date'
import {
  DATA_HEALTH_DIMENSIONS,
  RELATED_MEASURES_BY_DATA_HEALTH_DIMENSIONS
} from '~/constants/dataHealth'
import { FormatContext, MemberFormat } from '~/types/analyticsFormat'

export default {
  name: 'CheckCompletenessDimensionBlock',
  props: {
    dimensionHealthCheck: {
      type: Object as PropType<DimensionHealthCheck>,
      required: true
    },
    workforceCount: {
      type: Number,
      required: true
    }
  },
  setup() {
    return {
      getDataTypeTranslation,
      getMemberCategoryTranslation,
      getDimensionTranslation,
      getDefaultMeasureTranslation,
      THEME_BY_DATA_TYPE,
      getMemberFormatIcon,
      MemberType,
      formatMemberValue,
      MemberFormat,
      FormatContext,
      formatDate2
    }
  },
  data(): {
    isBlockFullyDisplayed: boolean
    displayAllIncompleteData: boolean
  } {
    return {
      isBlockFullyDisplayed: false,
      displayAllIncompleteData: false
    }
  },
  computed: {
    dimension() {
      return getDimensionFromTitle(
        this.dimensionHealthCheck.dimensionShortTitle
      )
    },
    dataType() {
      return getDataTypeFromMemberTitle(
        this.dimensionHealthCheck.dimensionShortTitle
      )
    },
    isComplete() {
      return this.dimensionHealthCheck.incompleteData.length === 0
    },
    incompleteCount() {
      return this.dimensionHealthCheck.incompleteData.length
    },
    impactedMeasures() {
      return RELATED_MEASURES_BY_DATA_HEALTH_DIMENSIONS[
        this.dimension.shortTitle as (typeof DATA_HEALTH_DIMENSIONS)[number]
      ]?.map(measure => getMeasureFromTitle(measure))
    },
    isAllIncompleteDataDisplayed() {
      return this.incompleteDataDisplayed.length === this.incompleteCount
    },
    incompleteDataDisplayed() {
      return this.displayAllIncompleteData
        ? this.dimensionHealthCheck.incompleteData
        : this.dimensionHealthCheck.incompleteData.toSpliced(5)
    }
  }
}
</script>

<style lang="scss" scoped>
$border-radius: 8px;

.block {
  width: 100%;
  background: $bg-secondary;
  border-radius: $border-radius;
  height: 100%;
  @include block-shadow;
  margin-bottom: $margin-small;

  &__header {
    height: 60px;
    display: flex;
    align-items: center;
    padding: 0 $margin-intermediate;
    justify-content: space-between;
    width: 100%;
    cursor: pointer;

    .header {
      &__title {
        display: flex;
        align-items: center;

        .title {
          &__category {
            @include font-text($font-weight-book);
            @include font-size($font-size-regular);
          }

          &__dimension {
            display: flex;
            align-items: center;

            .dimension {
              &__icon {
                @include font-icon;
                @include font-size($font-size-intermediate);
                color: $text-secondary;
                margin-right: $margin-tiny;
              }

              &__label {
                @include font-text($font-weight-book);
                @include font-size($font-size-regular);
                text-align: left;
                color: $text-secondary;
              }
            }
          }

          &__arrow {
            @include font-icon;
            @include font-size(10px);
            margin: 0 $margin-regular;
          }
        }
      }

      &__toggle {
        display: flex;
        @include font-icon;
        @include font-size(10px);

        &--on {
          transform: rotate(180deg);
        }
      }
    }
  }

  &__body {
    display: flex;
    max-height: 340px;
    border-top: 1px solid $border-quaternary;

    .body {
      &__first-col {
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: start;

        .first-col {
          &__total {
            display: flex;
            border-radius: $border-radius;
            background-color: $tag-septenary;
            align-items: center;
            margin: $margin-regular 0 $margin-small $margin-intermediate;

            &--complete {
              background-color: $tag-senary;
            }

            .total {
              &__icon {
                @include font-icon;
                @include font-size($font-size-tiny);
                margin: 0 $margin-tiny 0 $margin-small;
              }

              &__text {
                @include font-text($font-weight-book);
                @include font-size($font-size-mini);
                margin: $margin-small 0;
              }
            }
          }

          &__metrics {
            border-radius: $border-radius;
            border: 1px solid $border-quaternary;
            background-color: $bg-quaternary;
            margin: 0 0 $margin-regular $margin-intermediate;
            overflow: auto;

            .metrics {
              &__title {
                @include font-text($font-weight-medium);
                @include font-size($font-size-mini);
                margin: $margin-small $margin-regular;
              }

              &__sequence {
                display: flex;
                margin: $margin-tiny $margin-regular;
                align-items: center;

                &:last-of-type {
                  margin-bottom: $margin-regular;
                }

                .sequence {
                  &__icon {
                    @include font-icon;
                    @include font-size($font-size-regular);
                  }

                  &__label {
                    @include font-text($font-weight-book);
                    @include font-size($font-size-mini);
                  }
                }
              }
            }
          }

          &__improve {
            border-radius: $border-radius;
            border: 1px solid $border-quaternary;
            background-color: $bg-quaternary;
            margin: 0 0 $margin-regular $margin-intermediate;

            .improve {
              &__title {
                @include font-text($font-weight-medium);
                @include font-size($font-size-mini);
                margin: $margin-small $margin-regular;
              }

              &__text {
                @include font-text($font-weight-book);
                @include font-size($font-size-mini);
                margin: $margin-tiny $margin-regular;
                align-items: center;

                &:last-of-type {
                  margin-bottom: $margin-regular;
                }
              }
            }
          }
        }
      }

      &__second-col {
        width: 50%;
        flex: 1;
        display: flex;

        .second-col {
          &__list {
            overflow: auto;
            width: 100%;
            border-radius: $border-radius;
            border: 1px solid $border-quaternary;
            background-color: $bg-quaternary;
            margin: $margin-regular $margin-intermediate $margin-regular
              $margin-small;

            .list {
              &__header {
                @include font-text($font-weight-medium);
                @include font-size($font-size-mini);
                margin: $margin-small $margin-regular;
              }

              &__item {
                display: flex;
                justify-content: space-between;
                align-items: center;
                cursor: pointer;

                &:nth-child(even) {
                  background-color: $bg-secondary;
                }

                .item {
                  &__name {
                    margin: $margin-mini $margin-regular;
                    @include font-text($font-weight-book);
                    @include font-size($font-size-mini);
                  }

                  &__empty {
                    @include font-text($font-weight-book);
                    @include font-size($font-size-mini);
                    color: $text-tertiary;
                    margin-right: $margin-regular;
                  }
                }
              }

              &__tag {
                display: inline-block;
                border-radius: 22px;
                height: 25px;
                background-color: $tag-septenary;
                margin: $margin-intermediate 0 $margin-small
                  $margin-intermediate;
                align-content: center;
                cursor: pointer;

                .tag {
                  margin: $margin-tiny $margin-mini;
                  @include font-text($font-weight-book);
                  @include font-size($font-size-mini);
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
